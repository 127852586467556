import { ProductListItem } from 'src/components/CategoryListingPage/utils/productListItem';
import { ProductPrice, ProductType } from 'src/types/global-types';
import { formatNostoPrices } from 'src/components/Nosto/utilities/formatNostoPrices';

import type { NostoProductDataRaw, NostoProductData } from '../types';

const truncate = (content: string, maxLength: number) => {
    if (content.length > maxLength) {
        return content.substring(0, maxLength - 3) + '...';
    }

    return content;
};

const findDataInTags = (string: string, tags: string[]) => {
    let result = '';

    tags.forEach((tag) => {
        if (tag.includes(string)) {
            result = tag.split(':')[1];
        }
    });

    return result;
};

export const formatProducts = (products: NostoProductDataRaw[] | undefined): NostoProductData[] => {
    if (!Array.isArray(products)) return [];

    const cleanedNostoProducts: NostoProductData[] = [];

    products.map((product) => {
        const urlWithoutDomain = product.url.split('woodies.ie')?.[1];
        const minimumPrice = product.list_price;
        const specialPrice = product.price ?? undefined;

        // The type of the product is not exposed in the Nosto API
        // As a workaround, we can check if the product has skus to determine if it is configurable
        const isConfigurable = product.skus.length > 0;

        const clickAndCollect = findDataInTags('click_and_collected', product.tags1);
        const homeDelivery = findDataInTags('delivery_type', product.tags1);
        const isHomeDelivery = homeDelivery ? homeDelivery !== '-' : false;

        const price: ProductPrice = {
            minimum_price: minimumPrice,
            maximum_price: minimumPrice,
            minimum_sale_price: undefined,
            maximum_sale_price: undefined,
            special_price: undefined,
            discount: undefined,
        };

        const configurablePrice = formatNostoPrices(product.skus);

        if (specialPrice !== minimumPrice) {
            price.minimum_price = minimumPrice;
            price.special_price = specialPrice;
            // TODO: We should not be handling price calculations here. BE will need to expose this to nosto as a field
            price.discount = minimumPrice - specialPrice;
        }

        const formattedProduct: ProductListItem = {
            type: isConfigurable ? ProductType.ConfigurableProduct : ProductType.SimpleProduct,
            id: product.product_id ?? '',
            name: product.name ?? '',
            description: product.description ? truncate(product.description, 50) : '',
            url: urlWithoutDomain ?? product.url ?? '/',
            price: isConfigurable ? configurablePrice : price,
            sku: product.custom_fields?.sku ?? '',
            small_image: {
                label: product.name ?? '',
                url: product.image_url ?? '',
            },
            click_collect: clickAndCollect === 'Yes',
            delivery: isHomeDelivery,
            labels: [],
            rating_value: product.rating_value,
            review_count: product.review_count,
        };

        cleanedNostoProducts.push(formattedProduct);
    });

    return cleanedNostoProducts;
};

export default formatProducts;
