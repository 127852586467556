import { stripDomain } from 'src/util/sanitizeLink';
import { ProductPrice, ProductType } from 'src/types/global-types';
import { ProductListItem } from 'src/components/CategoryListingPage/utils/productListItem';
import { productName } from 'src/util/productName';
import { ProductHit } from 'src/components/Algolia/utils/algolia.types';

import getHitProductLabels from './getHitProductLabels';

interface HitToProductItemProps {
    currencyCode: string;
    customerGroupId?: number;
    hit: ProductHit;
    index: string;
}

export type ProductPriceHit = {
    default: number;
    defaultMax?: number;
    defaultOriginalFormated?: string;
    type: ProductType;
};

export function priceFromHit(props: ProductPriceHit): ProductPrice {
    const minimum_price = props.default;
    const maximum_price = props.defaultMax ?? minimum_price;

    /**
     * This value will help us to determine if the product is on sale or not
     * But is always returned as a string by algolia
     * Simple: "€52.99"
     * Configurable: "€52.99 - €72.99"
     */
    const defaultOriginalFormated = props.defaultOriginalFormated;

    /**
     * Default price object for simple products
     */
    const price: ProductPrice = {
        minimum_price,
        maximum_price,
        minimum_sale_price: minimum_price,
        maximum_sale_price: maximum_price,
        special_price: undefined,
        discount: undefined,
    };

    if (props.type === ProductType.SimpleProduct) {
        if (defaultOriginalFormated) {
            /**
             * If the Simple product is on sale
             * We assign the minimum price to the key special_price
             * We assign the original formated price to the key minimum_price
             *
             * This is because of how the algolia data is returned,
             * and we need to mimic Magentos data structure so that we can use the same components
             */
            price.minimum_price = parseFloat(defaultOriginalFormated?.slice(1));
            price.special_price = minimum_price;
        }
    }

    if (props.type === ProductType.ConfigurableProduct) {
        if (defaultOriginalFormated) {
            /**
             * If the Configurable product is on sale
             * We need to first convert the string values into a number format
             * Configurable: "€52.99 - €72.99"
             * We split the string into an array and remove the whitespace
             * We then assign the values to the price object
             */
            const priceFormat = defaultOriginalFormated.split('-');
            const minimum_sale_price = priceFormat[0].replace(' ', '');
            const maximum_sale_price = priceFormat[1]?.replace(' ', '');

            const minimum_sale_price_number = parseFloat(minimum_sale_price?.slice(1));
            const maximum_sale_price_number = parseFloat(maximum_sale_price?.slice(1));

            const isSamePrice = minimum_price === minimum_sale_price_number;

            if (!isSamePrice) {
                price.minimum_price = minimum_sale_price_number;
                price.maximum_price = maximum_sale_price_number;
                price.minimum_sale_price = minimum_price;
                price.maximum_sale_price = maximum_price;
            }
        }
    }

    return price;
}

export function hitToProductItem(props: HitToProductItemProps): ProductListItem {
    const { hit, currencyCode, index, customerGroupId } = props;
    const type: ProductType =
        hit.type_id === 'configurable' ? ProductType.ConfigurableProduct : ProductType.SimpleProduct;

    const price = priceFromHit({
        default: hit.price[currencyCode]?.default,
        defaultMax: hit.price[currencyCode]?.default_max,
        defaultOriginalFormated: hit.price[currencyCode]?.default_original_formated,
        type,
    });

    const url = `${stripDomain(hit.url)}?objectId=${hit.objectID}&queryId=${hit.__queryID}&indexName=${index}`;
    const deliveryTypes = ['Home Delivery', 'Delivery from Delivery Centre', 'Dropship'];

    const labels = getHitProductLabels(hit, customerGroupId);

    return {
        click_collect: hit.click_and_collected === 'Yes',
        delivery: deliveryTypes.includes(hit.delivery_type),
        id: hit.objectID,
        labels: labels,
        name: productName(hit.name),
        price,
        small_image: {
            label: hit.name,
            url: hit.image_url,
        },
        url: url,
        type,
    };
}
