import React from 'react';

import { Svg } from 'src/components/Svg/Svg';
import { ProductType } from 'src/types/global-types';

import { ProductListItem } from './utils/productListItem';
import classes from './ProductListDeliveryMethod.scss';

type ProductListDeliveryMethodProps = {
    product: ProductListItem;
};

export function ProductListDeliveryMethod(props: ProductListDeliveryMethodProps) {
    const { product } = props;

    const firstConfigurableVariant = product.configurableOptions?.variants?.[0];

    switch (product.type) {
        case ProductType.ConfigurableProduct:
            return (
                <DeliveryMethod
                    delivery={Boolean(firstConfigurableVariant?.product?.delivery)}
                    clickCollect={Boolean(firstConfigurableVariant?.product?.clickCollect)}
                />
            );
        default:
            return <DeliveryMethod delivery={product.delivery} clickCollect={product.click_collect} />;
    }
}

type DeliveryMethodProps = {
    delivery: boolean;
    clickCollect: boolean;
};

function DeliveryMethod(props: DeliveryMethodProps) {
    return (
        <span className={classes.delivery}>
            <span className={classes.item} data-active={String(props.delivery)}>
                <Svg name={props.delivery ? 'stock-available' : 'stock-unavailable'} /> Delivery
            </span>
            <span className={classes.item} data-active={String(props.clickCollect)}>
                <Svg name={props.clickCollect ? 'stock-available' : 'stock-unavailable'} /> Click & Collect
            </span>
        </span>
    );
}
