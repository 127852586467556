import React, { useContext } from 'react';
import { connectHitInsights } from 'react-instantsearch-dom';
import algoliaAnalytics from 'search-insights';
import { Link } from 'react-router-dom';

import { AlgoliaContext } from 'src/components/Algolia/SearchDropdown/AlgoliaContext';
import { ConfigContext } from 'src/components/AppShell/ConfigProvider';
import { useGetCustomerGroupID } from 'src/components/Customer/hooks';
import { hitToProductItem } from 'src/components/Algolia/utils';
import { imagePath } from 'src/util/formatUrl';
import { LazyImg } from 'src/hooks/useLazyLoad';
import { ProductMainPrice } from 'src/components/ProductDetail/ProductMainPrice';
import { ProductLabelsList } from 'src/components/ProductLabels/ProductLabelsList';

import classes from './AlgoliaProducts.scss';

export const Hit = ({ hit, insights }) => {
    const { indexes } = useContext(AlgoliaContext);

    const {
        currentCurrency: { code },
    } = useContext(ConfigContext);

    const { groupId } = useGetCustomerGroupID();

    const productFromHit = hitToProductItem({
        hit: hit,
        currencyCode: code,
        customerGroupId: groupId,
        index: indexes.products,
    });

    const { name, url, price, labels, small_image } = productFromHit;
    const hasProductLabels = labels && labels.length > 0;

    return (
        <Link
            to={url}
            className={classes.product}
            onClick={() => {
                insights('clickedObjectIDsAfterSearch', {
                    eventName: `Product Clicked: ${hit.sku}`,
                });
            }}
        >
            <figure className={classes.productFigure}>
                <LazyImg src={imagePath(small_image.url)} alt={name} className={classes.productImage} />
            </figure>
            <div className={classes.productInfo}>
                <div className={classes.productInfoLabels}>
                    {hasProductLabels && <ProductLabelsList labels={labels} size="micro" />}
                </div>
                <p className={classes.productInfoName}>{name}</p>
                <div className={classes.productInfoPrice}>
                    <ProductMainPrice productType={productFromHit.type} price={price} vat={true} layout="basket-item" />
                </div>
            </div>
        </Link>
    );
};

export const AlgoliaProductHit = connectHitInsights(algoliaAnalytics)(Hit);
